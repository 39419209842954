var glossary = [];


/**
 *@description Add all the glossary list.
 * @param {array} glossaryList glossary to be added.
 */
var addAll = function(glossaryList) {
    glossary = glossaryList;
};


/**
 *@description Add one element to the glossary.
 * @param {*} key key of the element.
 * @param {*} shortDescription short description of the glossary element.
 * @param {*} longDescription long description of the glossary element.
 */
var addOne = function(key, shortDescription, longDescription) {
    if (getGlossaryObject(key) !== null) {
        var index = glossary.findIndex((glossaryObj) => glossaryObj.key === key);

        glossary.splice(index, 1);
    }

    glossary.push({ key, shortDescription, longDescription });
};


/**
 *@description  Return the glossary list.
 * @return {array} List of glossary elements only with its long description.
 */
var getList = function() {
    return glossary.map((glossaryObj) => {
        return { key: glossaryObj.key, value: glossaryObj.longDescription };
    });
};

/**
 * @description Function to find glossary object in the existing list by passing key. If object exists returns object
 * @param {*} key key to be searched
 * @return {*}
 */
var getGlossaryObject = function(key) {
    var item = glossary.find((glossaryObj) => glossaryObj.key === key);

    return item ? item : null;
};

/**
 *
 * @description Function to get description of given input
 * @param {*} key key to be searched
 * @param {boolean} [shortDescription=true]
 * @return {*} 
 */
var getDescription = function(key, shortDescription = true) {
    var glossaryObject = getGlossaryObject(key);

    if (glossaryObject !== null) {
        return shortDescription ? glossaryObject.shortDescription : glossaryObject.longDescription;
    }

    return '';
};

/**
 *
 * @description Function to replace all occurrences of words that are in the glossary with the glossary labels
 * @param {*} input
 * @return {*} 
 */
var replace = function(input) {
    //TODO: Need to implement this to inspect the input and replace all occurrences of words that are in the glossary with the glossary labels

    return input;
};

const DuGlossaryUtilities = {
    Glossary: glossary,
    AddAll: addAll,
    AddOne: addOne,
    //Get: get,
    GetDescription: getDescription,
    //GetGlossaryList: getGlossaryList
    GetList: getList
};

export default DuGlossaryUtilities;
