import BlackSmallPng from '../Images/Black_Small.png';
import BlackSmallWebP from '../Images/Black_Small.webp';
import BlueSmallPng from '../Images/Blue_Small.png';
import BlueSmallWebP from '../Images/Blue_Small.webp';
import RaspSmallPng from '../Images/Rasp_Small.png';
import RaspSmallWebP from '../Images/Rasp_Small.webp';
import StrawSmallPng from '../Images/Straw_Small.png';
import StrawSmallWebP from '../Images/Straw_Small.webp';
import BlackMedPng from '../Images/Black_Medium.png';
import BlackMedWebP from '../Images/Black_Medium.webp';
import BlueMedPng from '../Images/Blue_Medium.png';
import BlueMedWebP from '../Images/Blue_Medium.webp';
import RaspMedPng from '../Images/Rasp_Medium.png';
import RaspMedWebP from '../Images/Rasp_Medium.webp';
import StrawMedPng from '../Images/Straw_Medium.png';
import StrawMedWebP from '../Images/Straw_Medium.webp';

/**
 * @description Function to get berry icons of various sizes
 */
const icons = {
    Black_Small_Png: BlackSmallPng,
    Blue_Small_Png: BlueSmallPng,
    Rasp_Small_Png: RaspSmallPng,
    Straw_Small_Png: StrawSmallPng,
    Black_Medium_Png: BlackMedPng,
    Blue_Medium_Png: BlueMedPng,
    Rasp_Medium_Png: RaspMedPng,
    Straw_Medium_Png: StrawMedPng,
    Black_Small_WebP: BlackSmallWebP,
    Blue_Small_WebP: BlueSmallWebP,
    Rasp_Small_WebP: RaspSmallWebP,
    Straw_Small_WebP: StrawSmallWebP,
    Black_Medium_WebP: BlackMedWebP,
    Blue_Medium_WebP: BlueMedWebP,
    Rasp_Medium_WebP: RaspMedWebP,
    Straw_Medium_WebP: StrawMedWebP
};

export default icons;
