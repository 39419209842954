const iconUtilities = {
    GetBerryLabel: function(berryType) {
        switch (berryType) {
            case 1:
            case '1':
            case 'BLACK':
                return 'Blackberry';
            case 2:
            case '2':
            case 'BLUE':
                return 'Blueberry';
            case 3:
            case '3':
            case 'RASP':
                return 'Raspberry';
            case 4:
            case '4':
            case 'STRAW':
                return 'Strawberry';
            default:
                return 'Unknown';
        }
    }
};

export default iconUtilities;
